<template>

  <div class="container">
    <router-view></router-view>
    <div class="downtitle">
      <a href="https://www.artefin.cz" target="_blank">
        <img class="logos" src="./assets/artefin.png" >
      </a>
      <a href="https://soutez.artefin.cz" target="_blank">
        <span>soutez.artefin.cz</span>
      </a>
      <a href="https://nft.callisto.network/ " target="_blank">
        <img class="logos" src="./assets/callisto_nft.png">
      </a>
    </div>

  </div>

</template>

<script>
export default {
name: 'App'
}

</script>

<style>

@import url('https://fonts.googleapis.com/css?family=Montserrat');


#silentbox-gallery{
  margin: 0 auto;
width: 90%;
text-align: center;
}

.silentbox-item {
padding:10px;

}

.container {
  padding-bottom: 10px;
  font-family: 'Montserrat';
}

body{
  background-color: black !important;
}

.logos {
  margin-left: 100px;
  margin-right: 100px;
}


.checkDiv {
  text-align: center;
}

.check {
  width: 60%;
}

.videoBlock {
  width: 90%;
  margin: 0 auto;
}

h1, h2 {
  color: white;
  text-align: center;
  padding-bottom: 20px !important;
  margin: 0px !important;
  font-family: 'Montserrat';
}

h3 {
  color: white;
  text-align: left;
  padding-bottom: 20px !important;
  margin: 0px !important;
  font-family: 'Montserrat';
}

h4 {
  color: #10df90;
  text-align: center;
  padding-bottom: 20px !important;
  margin: 0px !important;
  font-family: 'Montserrat';
}

h5 {
  color: #0a905d;
  text-align: left;
  margin: 0px !important;
  padding-top: 20px;
  font-family: 'Montserrat';
}

.bigGreenText {
  color: #10df90;
  text-align:center;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 1.4em;
}


.bigGreenText a {
  color: #10df90;
  font-family: 'Montserrat';
  font-weight: bold;
}

.bigWhiteText {
  color: white;
  text-align:center;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 1.4em;
}

.auctionInfo {
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
}

.toptitle{
  text-align: center;
  font-size: 46px;
  margin-top: 50px;
  margin-bottom: 60px;
  color: white;
  background-color: #339B76;
  font-family: 'Montserrat';
}

.toptitle span{
  color: white;
  background-color: #339B76;
  padding: 15px 22px;
  font-family: 'Montserrat';
}


.toptitle a{
  text-decoration: none !important;
}

.downtitle{
  text-align: center;
  font-size: 38px;
  margin-top: 50px;
  margin-bottom: 60px;
}

.downtitle span{
  color: white;
  background-color: #339B76;
  padding: 0px 5px 5px 5px;
}

.downtitle a{
  text-decoration: none !important;
}

.link{
  font-size: 22px;
  padding: 20px;
  color: antiquewhite;
  text-align: center;
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none 0 !important;
}

.bidBtn{
  text-align: center;
  margin: 40px 0;
}

.bidBtn a{
  text-decoration: none;
  color: white;
  padding-bottom: 6px;
  border-bottom: solid 1px #339B76;
  font-size: 18px;
}

.block3p_div{
  text-align: center;
}

.block3p{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: auto;

}

.cloInfo{
  color: white;
  font-size: 1em;
  text-align: justify;
  font-family: 'Montserrat';
}

.cloInfo a{
  color:#10df90;
  font-weight: bold;

  text-align: left;
}

.cloInfo a:hover{

color: #339B76;
}

.cloInfoDiv{
  width: 90%;
  text-align: left;
  margin:auto;
  padding-top: 50px;
  font-family: 'Montserrat';
}

.cloInfoDivIntro{
  width: 90%;
  text-align: left;
  margin:auto;
}

.cloInfoA a{
  text-decoration: none;
  color: white;
  padding-bottom: 4px;
  border-bottom: solid 1px #339B76;
  font-size: 18px;
}

.cloInfoA a{
  text-align: left;
}

.openAuction {
  color: white;
  font-size: 18px;
  text-align: center;
  padding-top: 30px;
}

.openAuction a{
  text-decoration: none;
  color: white;
  padding-bottom: 4px;
  border-bottom: solid 1px #339B76;
  font-size: 18px;

}


.cloInfoDivIntro a:hover{

  color: #339B76;
}

h4 a:hover{

color: #339B76;
}

.spanGreen {
  color:#10df90;
  font-weight: bold;
}

.spanGreen a{
  color:#10df90;
  font-weight: bold;
}

h4 a{
  color:#10df90;
  font-weight: bold;
}

.witdhControl {

margin: 0 auto;
 width: 80%;
}

.videoBlockText {
  width: 90%;
  margin: 0 auto;
  padding-top: 10px;
  font-family: 'Montserrat';
  text-align:justify;
}

.videoDescription {
  color: white;
  font-weight: bold;
  font-family: 'Montserrat';
}



.videoDescriptionText {
  color: white;

  font-size: 0.9em;
  font-family: 'Montserrat';
}

.videoDescriptionText a {
  color: white;
  font-family: 'Montserrat';
  font-weight: bold;
}



</style>
