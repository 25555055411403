import Vue from 'vue'
import VueRouter from 'vue-router'
import Bid from '../views/Bid.vue'
import LiveAuction from '../views/LiveAuction.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Bid',
    component: Bid
  },  
  {
    path: '/liveauction',
    name: 'LiveAuction',
    component: LiveAuction
  },


]

const router = new VueRouter({
  routes
})

export default router
