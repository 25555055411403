<template>

  <div class="container">
      <div class="toptitle">
          Dobročinná aukce vítězného NFT Artefin 2022
      </div>

      <div class="videoBlock">
        <div style="padding:56.25% 0 0 0;position:relative;"><iframe
            src="https://player.vimeo.com/video/746910126?h=47a01bd209&autoplay=1&loop=1&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
      </div>
      <div class="videoBlockText">
        <span class="videoDescription">Kryštof Brůha  </span><span class="videoDescription" style="padding-left:35px"> Magna Mutatio Natron</span><br>
        <span class="videoDescriptionText">Magna Mutatio je název série děl, která zkoumají transformaci vybraného území v čase, konkrétně proměnu měst, krajiny, vodních toků a ledovcových útvarů. Mapována je proměna území, které by při jeho velikosti a dlouhém časovém úseku pozorování, divák nemohl ze své perspektivy vnímat. Díla tak nabízí makro pohled na danou problematiku.
          <a href="https://soutez.artefin.cz/dila/magna-mutacio-natron/" target="_blank"> více zde</a>

        </span>

      </div>
      <div class="auctionInfo">
        <span class="bigGreenText">Aukce byla ukončena a výtěžek byl v plné výši věnován nadaci <a href="#kapka">Kapka Naděje</a>.</span>
      </div>

      <div>
        <silent-box :gallery="gallery"></silent-box>
        </div>



      <div id="kapka" class="cloInfoDiv">
        <span class="bigWhiteText">Na co přesně budou peníze z aukce použity? </span>
        <p class="cloInfo">
          Výtěžek z aukce bude využit na podporu dětské psychiatrie. Níže naleznete vzkaz od zakladatelky nadace Venduly Pizingerové.
        </p>
      </div>

      <div class="videoBlock">
        <div style="padding:56.25% 0 0 0;position:relative;"><iframe
            src="https://player.vimeo.com/video/748556347?h=47a01bd209&autoplay=0&loop=0&muted=0&title=0&byline=0&portrait=0"
            style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
      </div>


  </div>
</template>

<script>
  const axios = require('axios');
  const ethers = require('ethers');
  const contractABI = [{
  "inputs": [
    {
      "internalType": "uint256",
      "name": "_classID",
      "type": "uint256"
    },
    {
      "internalType": "string",
      "name": "_name",
      "type": "string"
    }
  ],
  "name": "bidOnNFT",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
},
  {
      "anonymous": false,
      "inputs": [
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "bid",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "bidder",
          "type": "address"
        }
      ],
      "name": "Bid",
      "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "bids",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "classID",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "bid_amount",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "bid_timestamp",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "auctions",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "max_supply",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "amount_sold",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "start_timestamp",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "duration",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "min_priceInWei",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "highest_bid",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "winner",
        "type": "address"
      },
      {
        "internalType": "string",
        "name": "winners_name",
        "type": "string"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }]
  const contractAddress = "0x39f8E6ECc31e0549c48bdb968e6C352F22Cbc844"
  const lprovider = new ethers.providers.JsonRpcProvider('https://rpc.callisto.network/')
  const lcontract = new ethers.Contract(contractAddress, contractABI, lprovider);

  export default {
      name: 'Bid',
      data: () => ({
          signer: "",
          provider: "",
          accounts: [],
          account:"",
          amount: "",
          name: "",
          bidder: "",
          lbid: "",
          bname: "",
          countdown: "",
          end: "",
          x: 0,
          cloPrice: 0,
          gallery:[
    {
        src: 'https://soutez.artefin.cz/wp-content/uploads/2022/09/Artefin_7.jpg',
        description: 'Předávání šeku Nadaci Kapka Naděje',
        alt: 'Předávání šeku Nadaci Kapka Naděje',
        thumbnailWidth: '280px'
    },
    {
        src: 'https://soutez.artefin.cz/wp-content/uploads/2022/09/Artefin_6.jpg',
        description: 'Corno Nero, Italy by Luca Zanon, from Unsplash.',
        alt: 'Předávání šeku Nadaci Kapka Naděje',
        thumbnailWidth: '280px'
    },
    {
        src: 'https://soutez.artefin.cz/wp-content/uploads/2022/09/Artefin_5.jpg',
        description: 'Předávání šeku Nadaci Kapka Naděje',
        alt: 'Předávání šeku Nadaci Kapka Naděje',
        thumbnailWidth: '280px'
    },
    {
        src: 'https://soutez.artefin.cz/wp-content/uploads/2022/09/Artefin_4.jpg',
        description: 'Předávání šeku Nadaci Kapka Naděje',
        alt: 'Předávání šeku Nadaci Kapka Naděje',
        thumbnailWidth: '280px'
    },
    {
        src: 'https://soutez.artefin.cz/wp-content/uploads/2022/09/Artefin_3.jpg',
        description: 'Předávání šeku Nadaci Kapka Naděje',
        alt: 'Předávání šeku Nadaci Kapka Naděje',
        thumbnailWidth: '280px'
    },
    {
        src: 'https://soutez.artefin.cz/wp-content/uploads/2022/09/Artefin_2.jpg',
        description: 'Předávání šeku Nadaci Kapka Naděje',
        alt: 'Předávání šeku Nadaci Kapka Naděje',
        thumbnailWidth: '280px'
    },
    {
        src: 'https://soutez.artefin.cz/wp-content/uploads/2022/09/Artefin_1.jpg',
        description: 'Předávání šeku Nadaci Kapka Naděje',
        alt: 'Předávání šeku Nadaci Kapka Naděje',
        thumbnailWidth: '280px'
    },
]
      }),
      async mounted() {
          await ethereum.on('accountsChanged', this.getAccount)
      },
      async beforeMount() {
          axios
              .get('https://api.coingecko.com/api/v3/coins/callisto')
              .then(res => {
              this.cloPrice = res.data.market_data.current_price.czk;
              })
              .catch(error => {
              console.error(error);
              });

          lcontract.on("Bid", (bid, bidder, event) => {
              let info = {
                  from: bidder,
                  value: bid,
                  data: event,
              }
              console.log(JSON.stringify(info, null, 3))
              this.getInfo();
          })

          this.getInfo();
          let auction = await lcontract.auctions("96")
          this.end = parseInt(ethers.utils.formatUnits(auction.start_timestamp, 0)+ "000") + parseInt(ethers.utils.formatUnits(auction.duration,0 )+ "000")
          this.getTime()
          },
      methods: {
          getAccount: async function (){
              this.provider = new ethers.providers.Web3Provider(window.ethereum, "any");
              this.accounts = await this.provider.send("eth_requestAccounts", []);
              this.account = this.accounts[0]
              this.signer = this.provider.getSigner(this.accounts[0]);
          },
          bid: async function () {
              let contract = new ethers.Contract(contractAddress, contractABI, this.signer);
              let options = {value: ethers.utils.parseEther(this.amount, "ether")}
              await contract.bidOnNFT("96", this.name, options)
              .then(value => {
                  console.log(value.hash)
              })
              .catch(err => {
                  console.log(err)
              })


          },
          getInfo: async function () {
              let bids = await lcontract.auctions("96")
              this.bidder = bids.winner.substring(0, 6)+"..."+bids.winner.substring(bids.winner.length -6, bids.winner.length);
              this.lbid = ethers.utils.formatEther(bids.highest_bid)
              this.bname = bids.winners_name
          },
          getTime: function() {
              var time = this;
              // Get today's date and time
              setInterval(function () {
              var now = new Date().getTime();

              // Find the distance between now and the count down date
              var distance = time.end - now - 1200000;

              // Time calculations for days, hours, minutes and seconds
              var days = Math.floor(distance / (1000 * 60 * 60 * 24));
              var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
              var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
              var seconds = Math.floor((distance % (1000 * 60)) / 1000);

              // Output the result in an element with id="demo"
              time.countdown = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
              // If the count down is over, write some text
              if (distance < 0) {
                  clearInterval(time.x);
                  time.countdown = "Aukce ukončena";
              }
              }, 1000);
          }
      }

  }


</script>
