<template>
    <div class="container">
      <div class="toptitle">
        Dobročinná aukce vítězného NFT Artefin 2022
      </div>
      <div class="block3p">
        <div class="block3p_div">
          <h3>Finální částka</h3>
          <!-- <h2>{{parseInt(this.bid)}} CLO ~({{parseFloat(this.bid*this.cloPrice).toFixed(2)}} Kč)</h2> -->
        <h2>1 400 000 CLO ~(98 711 Kč)</h2>
        </div>
        <div class="block3p_div">
          <h3>Zbývá</h3>
          <h2>{{this.countdown}}</h2>
        </div>
        <div class="block3p_div">
          <h3>Přihodil</h3>
          <h2>{{this.name}} ({{this.bidder}})</h2>
        </div>
      </div>

      <b-row><h4>Výtěžek z aukce bude v celé výši věnován nadaci Kapka Naděje.</h4></b-row>
        <div class="videoBlock">
          <div style="padding:56.25% 0 0 0;position:relative;"><iframe
              src="https://player.vimeo.com/video/746910126?h=47a01bd209&autoplay=1&loop=1&title=0&byline=0&portrait=0"
              style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
        <div class="openAuction">
        <a href="https://aukce.artefin.cz"><b>Otevřít aukci</b></a>
        </div>
    </div>
</template>

<script>
  const axios = require('axios');
  const ethers = require('ethers');
  const contractABI = [
    {
        "anonymous": false,
        "inputs": [
          {
            "indexed": false,
            "internalType": "uint256",
            "name": "bid",
            "type": "uint256"
          },
          {
            "indexed": false,
            "internalType": "address",
            "name": "bidder",
            "type": "address"
          }
        ],
        "name": "Bid",
        "type": "event"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "bids",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "classID",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "bid_amount",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "bid_timestamp",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "auctions",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "max_supply",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "amount_sold",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "start_timestamp",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "duration",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "min_priceInWei",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "highest_bid",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "winner",
          "type": "address"
        },
        {
          "internalType": "string",
          "name": "winners_name",
          "type": "string"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ]
  const contractAddress = "0x39f8E6ECc31e0549c48bdb968e6C352F22Cbc844"
  const provider = new ethers.providers.JsonRpcProvider('https://rpc.callisto.network/')
  const contract = new ethers.Contract(contractAddress, contractABI, provider);

  export default {
    name: 'LiveAuction',
    data: () => ({
      bidder: "",
      bid: "",
      name: "",
      countdown: "",
      end: "",
      x: 0,
      cloPrice: 0
    }),
    async beforeMount() {
      axios
        .get('https://api.coingecko.com/api/v3/coins/callisto')
        .then(res => {
          this.cloPrice = res.data.market_data.current_price.czk;
        })
        .catch(error => {
          console.error(error);
        });

      contract.on("Bid", (bid, bidder, event) => {
          let info = {
              from: bidder,
              value: bid,
              data: event,
          }
          console.log(JSON.stringify(info, null, 3))
          this.getInfo();
      })

      this.getInfo();
      let auction = await contract.auctions("96")
      this.end = parseInt(ethers.utils.formatUnits(auction.start_timestamp, 0)+ "000") + parseInt(ethers.utils.formatUnits(auction.duration,0 )+ "000")
      this.getTime()
    },
    methods: {
      getInfo: async function () {
        let bids = await contract.auctions("96")
        this.bidder = bids.winner.substring(0, 6)+"..."+bids.winner.substring(bids.winner.length -6, bids.winner.length);
        this.bid = ethers.utils.formatEther(bids.highest_bid)
        this.name = bids.winners_name
      },
      getTime: function() {
        var time = this;
        // Get today's date and time
        setInterval(function () {
          var now = new Date().getTime();

          // Find the distance between now and the count down date
          var distance = time.end - now - 1200000;

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24));
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          var seconds = Math.floor((distance % (1000 * 60)) / 1000);

          // Output the result in an element with id="demo"
          time.countdown = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
          // If the count down is over, write some text
          if (distance < 0) {
            clearInterval(time.x);
            time.countdown = "Aukce ukončena";
          }
        }, 1000);
      }

    }
  }
</script>
